#camping {
    margin-top: 70px;
    margin-bottom: 100px;
    position: relative;

    .soon-text {
        font-family: Urbanist-Medium;
        font-size: 18px;
        color: #fff;
        background: #202124;
        padding: 2px 5px;
        border-radius: 4px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        margin-top: 7px;
    }

    .content {
        display: flex;
        width: 100%;
        max-width: 1440px;
        justify-content: space-between;
        padding: 0px 24px;
        margin: 0 auto;

        .box-img {
            width: 100%;
            max-width: 825px;
            margin-right: 24px;

            img {
                width: 100%;
            }
        }

        .content-camping {
            .section-title {
                text-align: left;
                margin-bottom: 24px;
            }
            .text-camping {
                font-weight: 400;
                font-size: 24px;
                line-height: 33px;
                color: rgba(0, 0, 0, 0.8);
                margin-bottom: 35px;
                max-width: 543px;
                background: #fff;
            }
            .box-btns {
                display: flex;
                width: 100%;

                button {
                    width: 230px !important;
                    height: 48px !important;

                    &:first-of-type {
                        margin-right: 15px;
                    }
                }
            }
        }
    }

    .trail {
        position: absolute;
        top: -80px;
        right: 0;
        z-index: -1;
    }
    .trail-left {
        left: 0;
    }
}

@media (max-width: 990px) {
    #camping {
        .content {
            flex-direction: column;
            align-items: center;

            .box-img {
                margin-right: 0;
            }

            .content-camping {
                .section-title {
                    text-align: center;
                }
                .text-camping {
                    font-size: 20px;
                    text-align: center;
                    margin: 0 auto;
                }
                .box-btns {
                    flex-direction: column;
                    align-items: center;
                    button {
                        margin: 10px 0px;

                        &:first-of-type {
                            margin-right: 0px;
                        }
                    }
                }
            }
        }
        .trail {
            width: 150px;
        }
    }
}
