#camping-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    background: #337a2e;
    position: relative;
    flex-direction: column;

    .content {
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 24px 24px 48px 24px;
        z-index: 2;
        position: relative;
        padding-bottom: 0;

        .form-title {
            font-weight: 700;
            font-size: 36px;
            line-height: 43px;
            color: #ffffff;
            font-family: Urbanist-Bold;
            margin-bottom: 16px;
            text-align: center;
        }

        .form-subtitle {
            font-weight: 400;
            font-size: 20px;
            line-height: 27px;
            color: rgba(255, 255, 255, 0.8);
            margin-bottom: 48px;
            text-align: center;

            span {
                color: #337a2e;
                background: #fff;
                font-family: Urbanist-Bold;
                padding: 0px 5px;
            }
        }
    }
    .box-btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        margin-bottom: 70px;

        .hero-btn {
            font-family: Urbanist-Bold;
            width: 225px;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            color: #fff;
            text-transform: capitalize;
            border-radius: 5px;
            height: 48px;
            border: 1px solid #ffffff;

            &.btn-contained {
                background: #fff;
                border: 1px solid #fff;
                color: #337a2e;
            }
        }

        .btns-text {
            font-family: Urbanist-Regular;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: rgba(255, 255, 255, 0.8);
            margin: 0px 23px;
        }
    }

    .trail {
        position: absolute;
        z-index: 1;
    }
    .trail-left {
        left: 0;
        top: 30px;
    }

    .trail-right-1 {
        right: 0;
        top: 30px;
    }
    .trail-right-2 {
        right: 0;
        bottom: 0;
    }
}

@media (max-width: 990px) {
    #mail-form {
        .content {
            .form-subtitle {
                text-align: center;
            }
        }
        .trail-left {
            width: 150px;
        }
        .trail-right-2 {
            //width: 150px;
        }
        .trail-right-1 {
            display: none;
        }
    }
}

@media (max-width: 600px) {
    #mail-form {
        .box-btns {
            flex-direction: column;
            width: 100%;
            padding: 0px 24px;
            .hero-btn {
                width: 100%;
            }
            .btns-text {
                margin: 10px 0px;
            }
        }
    }
}
