#membership-page {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0px 24px;
    padding-top: 94px;

    .first-section {
        padding-top: 70px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 120px;
    }

    .container-content {
        width: calc(50% - 12px);
        margin-right: 24px;
        .title-section {
            font-family: Urbanist-SemiBold;
            font-style: normal;
            font-weight: 600;
            font-size: 48px;
            line-height: 58px;
            color: #000000;
            margin-bottom: 48px;
        }

        .title-content {
            font-family: Urbanist-SemiBold;
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 39px;
            color: #000000;
            margin-bottom: 80px;
        }

        .text-section {
            font-family: Urbanist-Regular;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 27px;
            color: rgba(0, 0, 0, 0.6);
            margin-bottom: 90px;
        }

        .items-section {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .item {
                width: 33.3%;
                margin-right: 15px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .item-img {
                    background: #26281f;
                    box-shadow: 2px 4px 8px 2px rgba(0, 0, 0, 0.25);
                    border-radius: 5px;
                    width: 72px;
                    height: 72px;
                    padding: 12px;
                    margin-bottom: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .item-title {
                    font-family: Urbanist-Bold;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 24px;
                    text-align: center;
                    color: #000000;
                    margin-bottom: 16px;
                }
                .item-text {
                    font-family: Urbanist-Regular;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    text-align: center;
                    color: rgba(0, 0, 0, 0.6);
                }
            }
        }
    }
    .container-image {
        width: calc(50% - 12px);

        img {
            width: 100%;
        }
    }
    .second-section {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 120px;

        .container-content {
            margin-right: 0;
        }
        .container-image {
            margin-right: 24px;
        }
    }

    .third-section {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 70px;
        .container-content {
            .item {
                width: 50%;
                max-width: 240px;
                margin-right: 15px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .item-img {
                    background: transparent;
                    box-shadow: none;
                    border-radius: 5px;
                    width: 72px;
                    height: 72px;
                    padding: 12px;
                    margin-bottom: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .btn {
        font-family: Urbanist-Bold;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #ffffff;
        background: #4bb543;
        border-radius: 5px;
        text-transform: capitalize;
        height: 48px;

        &.btn-safety {
            display: block;
            margin: 0 auto;
            margin-top: 70px;
        }
    }

    .trail {
        position: absolute;
        z-index: -1;
    }
    .trail-1 {
        top: 0;
        left: 260px;
    }
    .trail-2 {
        top: -120%;
        right: 0;
    }
    .trail-3 {
        bottom: -30%;
        right: 0;
    }
}

@media (max-width: 999px) {
    #membership-page {
        .first-section,
        .second-section,
        .third-section {
            flex-direction: column;

            .container-content {
                width: 100%;
                margin-right: 0;
            }
            .container-image {
                width: 100%;
                margin-right: 0;
            }
        }
        .first-section {
            .container-content {
                margin-bottom: 24px;
            }
        }

        .btn-safety {
            margin-top: 24px !important;
            margin-bottom: 24px !important;
        }
    }
    .trail {
        display: none;
    }
}
