#hero-camping {
    width: 100%;
    height: 800px;
    background-image: url(../../../assets/images/Associations/hero.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 0px 24px;

    &:after {
        content: "";
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.32);
    }

    .box-info {
        width: 100%;
        max-width: 462;
        z-index: 2;
        background: rgba(0, 0, 0, 0.6);
        padding: 12px;
        margin-bottom: 36px;

        .hero-title {
            font-family: Urbanist-SemiBold;
            font-weight: 600;
            font-size: 32px;
            line-height: 44px;
            color: #ffffff;
            margin-bottom: 14px;
        }

        .hero-subtitle {
            font-family: Urbanist-Regular;
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 33px;
            color: #ffffff;
            margin-bottom: 14px;
            display: flex;
            align-items: center;

            .divider {
                height: 31px;
                width: 1px;
                background: rgba(255, 255, 255, 0.2);
                margin: 0px 8px;
            }

            span {
                font-size: 24px;
                line-height: 33px;
                color: rgba(255, 255, 255, 0.6);
            }
        }

        .description {
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 27px;
            font-family: Urbanist-Regular;
            color: rgba(255, 255, 255, 0.8);
        }
    }
}

@media (max-width: 990px) {
    #hero-camping {
        .box-info {
        }
    }
}
