#events {
    background-image: url(../../../assets/images/Home/events.jpg);
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 454px;
    display: flex;
    align-items: center;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
    }

    .content {
        width: 100%;
        padding: 24px;
        position: relative;
        z-index: 3;
        max-width: 1440px;
        margin: 0 auto;

        .content-header {
            display: flex;
            align-items: center;
            .title-events {
                font-family: Urbanist-Bold;
                font-weight: 700;
                font-size: 48px;
                line-height: 48px;
                color: #ffffff;
                margin-bottom: 12px;
                margin-right: 10px;
            }
            .soon-text {
                font-family: Urbanist-Medium;
                font-size: 18px;
                color: #202124;
                background: #fff;
                padding: 2px 5px;
                border-radius: 4px;
            }
        }
        .text-events,
        .include-text {
            font-size: 24px;
            line-height: 33px;
            color: rgba(255, 255, 255, 0.8);
            max-width: 371px;
            margin-bottom: 10px;
        }

        .include-text {
            font-size: 24px;
            font-family: Urbanist-Medium;
            text-decoration: underline;
        }

        ul {
            margin-left: 24px;
            margin-bottom: 15px;
            li {
                list-style: disc;
                font-size: 22px;
                line-height: 30px;
                color: #fff;
            }
        }
    }
}

@media (max-width: 990px) {
    #events {
        background-position: bottom;
        .content {
            .title-events {
                font-size: 35px;
            }
            .text-events {
                font-size: 20px;
            }
            button {
                font-size: 20px !important;
            }
        }
    }
}
