#header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background: #26281f;
    box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.25);
    z-index: 9999;
    transition: all 0.3s;

    .launch-date {
        width: 100%;
        background: #337a2e;

        p {
            width: 100%;
            text-align: center;
            display: block;
            font-weight: 400;
            font-size: 18px;
            line-height: 18px;
            color: #fff;
            padding: 5px;
        }
    }

    .header-nav {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1440px;
        margin: 0 auto;
        padding: 25px;

        .links-nav {
            display: flex;
            align-items: center;
            max-width: 378px;
            width: 100%;

            &:last-of-type {
                justify-content: flex-end;
            }

            .link-nav {
                font-family: Urbanist-Semibold;
                margin-right: 48px;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                color: #ffffff;
                transition: all 0.5s;
                position: relative;

                &:after {
                    content: "";
                    width: 56px;
                    height: 1px;
                    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='white' stroke-width='2' stroke-dasharray='7%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
                    position: absolute;
                    bottom: -5px;
                    left: 50%;
                    transform: translateX(-50%);
                    transition: all 0.3s;
                    opacity: 0;
                    visibility: hidden;
                    margin: 0 auto;
                }

                &:last-of-type {
                    margin-right: 0;
                }

                &:hover {
                    &:after {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            .link-sub {
                background: #4bb543;
                border-radius: 5px;
                padding: 9px 12px;
                font-size: 20px;
                text-transform: capitalize;
                width: 140px;
                text-align: center;
                border: 1px solid #4bb543;
                transition: all 0.3s;

                &:hover {
                    color: #4bb543;
                    background: #ccffc8;
                }

                &:after {
                    display: none;
                }
            }
        }

        .links-nav-true {
            .link-nav {
                visibility: hidden;
            }
            .link-sub {
                visibility: visible;
            }
        }

        .box-logo {
            display: block;
            outline: 0;
            max-width: 70px;
            height: 34px;
            transform: translateY(-20px);

            img {
                width: 100%;
            }
        }
    }
    .nav-mobile {
        display: none;
    }
}

.header-transparent {
    background: rgba(0, 0, 0, 0.4) !important;
    box-shadow: none;
}

@media (max-width: 990px) {
    #header {
        .launch-date {
            p {
                font-size: 16px;
                padding: 2px;
            }
        }
        .header-nav {
            display: none;
        }
        .nav-mobile {
            display: flex;
            justify-content: space-between;
            padding: 5px 24px;
            align-items: center;
            position: relative;

            .box-logo {
                display: block;
                outline: 0;
                max-width: 60px;

                img {
                    width: 100%;
                }
            }

            .icon-menu {
                color: #fff;
                cursor: pointer;
                display: block;
                width: 24px;
            }

            .menu-mobile {
                position: fixed;
                width: 100vw;
                height: 100vh;
                background: #26281f;
                transition: all 0.3s;
                opacity: 0;
                visibility: hidden;
                top: 0;
                left: 0;
                padding: 0px 24px;

                .header-menu {
                    width: 100%;
                    position: relative;
                    padding: 20px 24px;
                    display: flex;
                    justify-content: center;

                    .close-icon {
                        position: absolute;
                        right: 20px;
                        top: 20px;
                        color: #fff;
                        cursor: pointer;
                    }
                }

                .links-nav {
                    display: flex;
                    width: 100%;
                    margin-top: 70px;
                    flex-direction: column;

                    .link-nav {
                        font-family: Urbanist-Semibold;
                        margin-right: 48px;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 24px;
                        color: #ffffff;
                        transition: all 0.5s;
                        position: relative;
                        margin: 5px 0px;
                    }

                    .link-sub {
                        background: #4bb543;
                        border-radius: 5px;
                        padding: 9px 12px;
                        text-transform: uppercase;
                        width: 130px;

                        &:after {
                            display: none;
                        }
                    }
                }

                .links-nav-true {
                    .link-nav {
                        visibility: hidden;
                    }
                    .link-sub {
                        visibility: visible;
                    }
                }
            }
            .menu-active {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
