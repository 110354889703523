#hero-camping {
    width: 100%;
    height: 841px;
    background-image: url(../../../assets/images/Camping/hero.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 24px;

    &:after {
        content: "";
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.32);
    }

    .box-info {
        width: 100%;
        max-width: 520px;
        z-index: 2;

        .hero-title {
            font-family: Urbanist-Bold;
            font-weight: 700;
            font-size: 64px;
            line-height: 77px;
            color: #ffffff;
            margin-bottom: 24px;
        }

        .hero-subtitle {
            font-family: Urbanist-SemiBold;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 33px;
            color: #ffffff;
            margin-bottom: 24px;
        }

        .box-btns {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .hero-btn {
                font-family: Urbanist-Bold;
                width: 225px;
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                color: #ffffff;
                text-transform: capitalize;
                border-radius: 5px;
                height: 48px;
                border: 1px solid #ffffff;

                &.btn-contained {
                    background: #4bb543;
                    border: 1px solid #4bb543;
                }
            }

            .btns-text {
                font-family: Urbanist-Regular;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                color: rgba(255, 255, 255, 0.8);
            }
        }
    }
}

@media (max-width: 990px) {
    #hero-camping {
        .box-info {
            .box-btns {
                flex-direction: column;

                .hero-btn {
                    width: 100%;
                }
                .btns-text {
                    margin: 10px 0px;
                }
            }
        }
    }
}
