#home {
    .section-title {
        font-family: Urbanist-Bold;
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        color: #26281f;
        text-align: center;
        position: relative;
    }
    .section-subtitle {
        font-family: Urbanist-Light;
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        color: rgba(0, 0, 0, 0.6);
        width: 100%;
        text-align: center;
        margin-top: 12px;
    }
}

@media (max-width: 990px) {
    #home {
        .section-title {
            font-size: 32px;
        }
        .section-subtitle {
            font-size: 18px;
        }
    }
}
