#how-it-works {
    width: 100%;
    position: relative;
    padding: 72px 0px;

    .items {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1440px;
        margin: 0 auto;
        margin-top: 72px;
        margin-bottom: 42px;
        padding: 0px 24px;

        .item {
            width: 100%;
            max-width: 300px;
            min-height: 225px;
            box-shadow: 2px 4px 8px 2px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transition: all 0.3s;
            background: #337a2e;
            padding: 15px;

            .item-content {
                border: 2px dashed #fff;
                width: 100%;
                height: 205px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
            }

            &:hover {
                box-shadow: 2px 6px 8px 6px rgba(0, 0, 0, 0.25);
                transform: translateY(-10px);
            }
            .icon {
                display: block;
                margin-bottom: 12px;
            }

            .item-title {
                font-family: Urbanist-Bold;
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                color: #fff;
            }

            .top-card {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                background: #337a2e;
                border-radius: 5px;
                padding-top: 24px;
                padding-bottom: 12px;
                height: 120px;
            }

            .item-text {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                color: #26281f;
                padding: 24px 8px;
            }
        }
        .box-arrow {
            display: block;
            max-width: 120px;
            margin: 0px 15px;
        }
        .arrow-hiw {
            width: 100%;
        }
    }
    .trail {
        position: absolute;
        z-index: -1;
    }

    .trail-upper-left {
        left: 0;
        top: 0;
    }

    .trail-upper-right-1,
    .trail-upper-right-2 {
        right: 0;
        top: 0;
    }

    .trail-lower-left {
        left: 0;
        bottom: -65%;
    }

    .box-btn {
        display: flex;
        justify-content: center;
    }
}

@media (max-width: 990px) {
    #how-it-works {
        .items {
            flex-direction: column;

            .item {
                margin-bottom: 24px;
            }

            .box-arrow {
                display: none;
                margin: 15px 0px;
                transform: rotate(90deg);
                width: 70px;
                height: 120px;
            }
        }
        .trail-upper-right-1 {
            display: none;
        }
        .trail-upper-right-2 {
            width: 200px;
        }
        .trail-upper-left {
            top: -70px;
            width: 150px;
        }
        .trail-lower-left {
            left: -50%;
            bottom: 0;
        }
    }
}
