#subscribePage {
    width: 100%;
    background: linear-gradient(180deg, #3d862d 0%, #2c6121 100%);
    background-blend-mode: multiply;
    position: relative;
    min-height: 100vh;
    position: relative;

    .trail {
        position: absolute;
        z-index: 1;
    }

    .trail-left-1 {
        left: 0;
        bottom: 0;
    }

    .trail-left-2 {
        left: 0;
        bottom: 15px;
    }

    .trail-right-1,
    .trail-right-2 {
        top: 0;
        right: 0;
    }

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px;

        .back-icon {
            color: #fff;
        }

        .box-logo {
            display: block;
            outline: 0;
            max-width: 45px;

            img {
                width: 100%;
            }
        }
    }

    .content {
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 24px 24px 48px 24px;
        z-index: 2;
        min-height: calc(100vh - 111px);
        justify-content: center;

        .form-title {
            font-size: 48px;
            line-height: 58px;
            color: #ffffff;
            font-family: Urbanist-Bold;
            margin-bottom: 16px;
            display: flex;
            align-items: center;

            .checkIcon {
                width: 48px;
                height: 48px;
                color: #fff;
                margin-left: 24px;
            }
        }

        .form-subtitle {
            font-weight: 400;
            font-size: 20px;
            line-height: 27px;
            color: rgba(255, 255, 255, 0.8);
            margin-bottom: 48px;
            margin-top: 24px;
            text-align: center;
            max-width: 544px;

            span {
                color: #337a2e;
                background: #fff;
                font-family: Urbanist-Bold;
                padding: 0px 5px;
            }
        }
    }
}

@media (max-width: 990px) {
    #subscribePage {
        .trail {
            width: 150px;
        }
        .trail-left-1 {
            display: none;
        }
        .trail-right-1 {
            display: none;
        }
        .content {
            .form-subtitle {
                text-align: center;
            }
        }
    }
}
