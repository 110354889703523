#sites-camping {
    width: 100%;

    .title {
        font-weight: 700;
        font-size: 36px;
        line-height: 44px;
        font-family: Urbanist-Bold;
        color: #000000;
        width: 100%;
        text-align: center;
        margin-bottom: 32px;
        padding: 0px 24px;
    }
    .divider {
        width: 100%;
        height: 1px;
        background: rgba(0, 0, 0, 0.2);
        display: block;
        margin-bottom: 30px;
    }

    .header-sites {
        margin: 0 auto;
        display: flex;
        padding: 0px 24px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 1440px;

        .text-header {
            font-family: Urbanist-SemiBold;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: rgba(0, 0, 0, 0.4);
        }
    }
    .content-sites {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 1440px;
        padding: 0px 24px;
        flex-wrap: wrap;
        margin: 0 auto;

        .card {
            width: calc(25% - 10px);
            margin-top: 24px;
            transition: all 0.3s;

            &:hover {
                .box-img {
                    img {
                        transform: scale(1.05);
                    }
                }
            }
            &:first-of-type {
                margin-right: 10px;
            }
            p {
                font-weight: 400;
                line-height: 19px;
                color: rgba(0, 0, 0, 0.6);

                strong {
                    color: #000;
                }
            }

            .box-img {
                display: block;
                width: 100%;
                max-width: 324px;
                overflow: hidden;

                img {
                    width: 100%;
                    border-radius: 4px;
                    transition: all 0.3s;
                }
            }

            .card-rating {
                display: flex;
                margin-top: 8px;

                p,
                span {
                    font-style: normal;
                    font-weight: 400;
                    line-height: 17px;
                    color: rgba(0, 0, 0, 0.6);
                }

                span {
                    color: #000;
                    margin: 0px 2px;
                }
            }
            .card-title {
                font-family: Urbanist-Medium;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                color: #000000;
                margin: 4px 0px;
            }

            .card-description {
                font-weight: 400;
                line-height: 19px;
                color: rgba(0, 0, 0, 0.8);
                margin-top: 12px;
            }
        }
    }
    .pagination {
        width: 100%;
        max-width: 1440px;
        padding: 0px 24px;
        margin: 0 auto;
        margin-top: 48px;
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
    }
}

@media (max-width: 990px) {
    #sites-camping {
        .content-sites {
            .card {
                width: calc(50% - 10px);
            }
        }
    }
}

@media (max-width: 767px) {
    #sites-camping {
        .content-sites {
            flex-direction: column;
            align-items: center;
            .card {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-right: 0;

                .card-description {
                    max-width: 324px;
                    text-align: center;
                }
            }
        }
    }
}

@media (max-width: 425px) {
    #sites-camping {
        .header-sites {
            flex-direction: column;
            align-items: center;

            .text-header {
                margin-bottom: 48px;
            }

            .order {
                width: 100%;
                max-width: 324px;
            }
        }
    }
}
