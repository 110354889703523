#trail-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;

  h1 {
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 10px;
  }

  .trail-details-btn {
    padding: 12px 20px;
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    color: #ffffff;
    background: #4bb543;
    border-radius: 5px;
    cursor: pointer;
  }
}