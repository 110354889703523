#mail-form {
    width: 100%;
    display: flex;
    justify-content: center;
    background: #337a2e;
    position: relative;

    .content {
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 24px 24px 48px 24px;
        z-index: 2;
        position: relative;

        .form-title {
            font-size: 48px;
            line-height: 58px;
            color: #ffffff;
            font-family: Urbanist-Bold;
            margin-bottom: 16px;
        }

        .form-subtitle {
            font-weight: 400;
            font-size: 20px;
            line-height: 27px;
            color: rgba(255, 255, 255, 0.8);
            margin-bottom: 48px;

            span {
                color: #337a2e;
                background: #fff;
                font-family: Urbanist-Bold;
                padding: 0px 5px;
            }
        }

        form {
            display: flex;
            align-items: center;

            .form-input {
                margin-right: 24px;
                background: #fff;
                border-radius: 4px;
                border: none;
                height: 53px;
                width: 448px;

                .MuiInputBase-root {
                    height: 53px;
                }
            }

            .form-btn {
                height: 53px;
                width: 170px;
                border: 1px solid #fff;
                font-size: 24px;
                line-height: 29px;
                font-family: Urbanist-Bold;
                color: #ffffff;
                text-transform: capitalize;
                transition: all 0.3s;

                &:hover {
                    color: #337a2e;
                    background: #fff;
                }
            }
        }
    }

    .trail {
        position: absolute;
        z-index: 1;
    }
    .trail-left {
        left: 0;
        top: 30px;
    }

    .trail-right-1 {
        right: 0;
        top: 30px;
    }
    .trail-right-2 {
        right: 0;
        bottom: 0;
    }
}

@media (max-width: 990px) {
    #mail-form {
        .content {
            .form-subtitle {
                text-align: center;
            }
            form {
                flex-direction: column;
                width: 100%;

                .form-input {
                    max-width: 100%;
                    margin-bottom: 24px;
                    margin-right: 0;
                }
                .form-btn {
                    width: 100%;
                }
            }
        }
        .trail-left {
            width: 150px;
        }
        .trail-right-2 {
            //width: 150px;
        }
        .trail-right-1 {
            display: none;
        }
    }
}
