#associations {
    position: relative;
    .soon-text {
        font-family: Urbanist-Medium;
        font-size: 18px;
        color: #fff;
        background: #202124;
        padding: 2px 5px;
        border-radius: 4px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        margin-top: 7px;
    }
    .content {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        margin-top: 48px;
        padding: 0px 24px;
        z-index: 2;

        .content-cards {
            display: flex;
            justify-content: space-between;
            width: 50%;

            .card {
                width: 50%;
                &:first-of-type {
                    margin-right: 10px;
                }
                p {
                    font-weight: 400;
                    line-height: 19px;
                    color: rgba(0, 0, 0, 0.6);

                    strong {
                        color: #000;
                    }
                }

                .box-img {
                    display: block;
                    width: 100%;
                    max-width: 324px;

                    img {
                        width: 100%;
                        border-radius: 4px;
                    }
                }

                .card-rating {
                    display: flex;
                    margin-top: 8px;

                    p,
                    span {
                        font-style: normal;
                        font-weight: 400;
                        line-height: 17px;
                        color: rgba(0, 0, 0, 0.6);
                    }

                    span {
                        color: #000;
                        margin: 0px 2px;
                    }
                }
                .card-title {
                    font-family: Urbanist-Medium;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                    color: #000000;
                    margin: 4px 0px;
                }

                .card-description {
                    font-weight: 400;
                    line-height: 19px;
                    color: rgba(0, 0, 0, 0.8);
                    margin-top: 12px;
                }
            }
        }
        .content-info {
            width: 50%;
            margin-left: 15px;

            ul {
                height: 232px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-bottom: 48px;
                .item-info {
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 29px;
                    color: #000000;
                    display: flex;
                    align-items: center;
                    background: #fff;

                    &:before {
                        content: "";
                        width: 3px;
                        height: 3px;
                        background: #000;
                        border-radius: 100%;
                        margin-right: 15px;
                    }
                }
            }
            .box-btn {
                max-width: 320px;
                display: block;

                button {
                    width: 100% !important;
                }
            }
        }
    }
    .trail {
        position: absolute;
        top: 20%;
        right: 0;
        z-index: -1;
    }
}
@media (max-width: 990px) {
    #associations {
        .content {
            flex-direction: column;
            .content-cards {
                width: 100%;
                margin-bottom: 24px;
            }
            .content-info {
                width: 100%;
            }
        }
        .trail {
            // display: none;
        }
    }
}
