#footer {
    width: 100%;
    padding: 24px;
    background: #3e3e3e;

    p {
        width: 100%;
        text-align: center;
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #ffffff;
        font-family: Urbanist-Light;

        &:last-of-type {
            font-size: 16px;
            margin-top: 5px;
        }
    }
}
