#connect-associations {
    .title {
        width: 100%;
        text-align: center;
        padding-top: 72px;
        font-family: Urbanist-Medium;
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 58px;
        color: #000000;
        margin-bottom: 48px;
    }
    .text {
        text-align: center;
        font-family: Urbanist-Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        color: rgba(0, 0, 0, 0.8);
        max-width: 960px;
        margin: 0 auto;
        margin-bottom: 80px;
    }
    .items {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 48px;

        .item {
            width: 100%;
            max-width: 384px;
            margin-right: 72px;
            display: flex;
            flex-direction: column;
            align-items: center;

            &:last-of-type {
                margin-right: 0;
            }

            img {
                margin-bottom: 34px;
            }

            .text-item {
                font-family: Urbanist-Regular;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                text-align: center;
                color: rgba(0, 0, 0, 0.8);
            }
        }
    }
    .actions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 72px;

        .btn-contained,
        .btn-outlined {
            background: #4bb543;
            border-radius: 5px;
            width: 147px;
            height: 48px;
            font-family: Urbanist-Bold;
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            color: #ffffff;
            text-transform: capitalize;
        }
        .btn-outlined {
            background: transparent;
            border: 1px solid #4bb543;
            color: #4bb543;
        }
        span {
            font-family: Urbanist-Regular;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: rgba(0, 0, 0, 0.4);
            margin: 0px 23px;
        }
    }
}

@media (max-width: 767px) {
    #connect-associations {
        padding: 0px 24px;

        .items {
            flex-direction: column;

            .item {
                margin-right: 0;
                margin-bottom: 32px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}
