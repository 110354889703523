.events-calendar {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0px 30px;
    .section-filter {
        margin-top: 48px;

        &:last-of-type {
            margin-top: 68px;
            margin-bottom: 68px;
        }

        .title-filter {
            font-family: Urbanist-Light;
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.6);
            margin-bottom: 60px;
        }

        .items-filter {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
        }

        .item-filter {
            width: calc(16.6% - 20px);
            margin-right: 20px;
            cursor: pointer;

            &:last-of-type {
                margin-right: 0;
            }

            .icon-item {
                width: 100%;
                height: 88px;
                background-image: url("../../../assets/icons/Events/SVG-Black/Bike.svg");
                display: block;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                transition: all 0.3s;

                &.item-snow {
                    background-image: url("../../../assets/icons/Events/SVG-Black/Snow.svg");
                }

                &.item-horse {
                    background-image: url("../../../assets/icons/Events/SVG-Black/Hiking.svg");
                }

                &.item-hiking {
                    background-image: url("../../../assets/icons/Events/SVG-Black/Hiker.svg");
                }

                &.item-running {
                    background-image: url("../../../assets/icons/Events/SVG-Black/Running.svg");
                }

                &.item-shotting {
                    background-image: url("../../../assets/icons/Events/SVG-Black/Shotting.svg");
                }

                &.item-hunter {
                    background-image: url("../../../assets/icons/Events/SVG-Black/Aim.svg");
                }

                &.item-polo {
                    background-image: url("../../../assets/icons/Events/SVG-Black/Polo.svg");
                }

                &.item-clinics {
                    background-image: url("../../../assets/icons/Events/SVG-Black/Clinics.svg");
                }
            }

            .title-item {
                font-family: Urbanist-Regular;
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 27px;
                color: rgba(0, 0, 0, 0.8);
                text-align: center;
                transition: all 0.3s;
            }

            &:hover {
                .icon-item {
                    background-image: url("../../../assets/icons/Events/SVG-Green/Bike.svg");

                    &.item-snow {
                        background-image: url("../../../assets/icons/Events/SVG-Green/Snow.svg");
                    }

                    &.item-horse {
                        background-image: url("../../../assets/icons/Events/SVG-Green/Hiking.svg");
                    }

                    &.item-hiking {
                        background-image: url("../../../assets/icons/Events/SVG-Green/Hiker.svg");
                    }

                    &.item-running {
                        background-image: url("../../../assets/icons/Events/SVG-Green/Running.svg");
                    }

                    &.item-shotting {
                        background-image: url("../../../assets/icons/Events/SVG-Green/Shotting.svg");
                    }

                    &.item-hunter {
                        background-image: url("../../../assets/icons/Events/SVG-Green/Aim.svg");
                    }

                    &.item-polo {
                        background-image: url("../../../assets/icons/Events/SVG-Green/Polo.svg");
                    }

                    &.item-clinics {
                        background-image: url("../../../assets/icons/Events/SVG-Green/Clinics.svg");
                    }
                }
                .title-item {
                    color: #4bb543;
                }
            }
        }
    }
    .section-calendar {
        .title-calendar {
            font-family: Urbanist-Bold;
            font-style: normal;
            font-weight: 700;
            font-size: 64px;
            line-height: 77px;
            color: #000000;
            margin-bottom: 48px;
            margin-top: 70px;
        }
        .item-calendar {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 170px;
            margin-bottom: 48px;

            .day-calendar {
                width: 100%;
                max-width: 172px;
                background: linear-gradient(90deg, #4bb543 0%, #337a2e 100%);
                height: 100%;
                font-family: Urbanist-Bold;
                font-style: normal;
                font-weight: 700;
                font-size: 48px;
                line-height: 58px;
                color: #ffffff;
                padding: 16px;
            }
            .content-calendar {
                height: 100%;
                .title-content {
                    font-family: Urbanist-Bold;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 48px;
                    line-height: 58px;
                    color: #000000;
                    margin-bottom: 16px;
                }
                .text-calendar {
                    font-family: Urbanist-Regular;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 27px;
                    color: rgba(0, 0, 0, 0.6);
                    margin-bottom: 8px;
                    display: flex;
                    align-items: center;

                    img {
                        margin-right: 16px;
                        width: 19px;
                    }

                    span {
                        color: #000;
                        margin-right: 5px;
                        &:last-of-type {
                            margin-left: 48px;
                        }
                    }
                }
            }
            .register-calendar {
                width: 100%;
                max-width: 416px;
                background: linear-gradient(270deg, #4bb543 0%, #337a2e 100%);
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                height: 100%;
                padding: 16px;

                .btn {
                    border-color: #fff;
                    color: #fff;
                    border-radius: 5px;
                    font-family: Urbanist-Bold;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 24px;
                    color: #ffffff;
                    text-transform: capitalize;
                    width: 147px;
                    height: 48px;
                }
            }
        }
    }
}

@media (max-width: 999px) {
    .events-calendar {
        .section-filter {
            .items-filter {
                flex-wrap: wrap;

                .item-filter {
                    width: 30%;
                    margin-bottom: 16px;
                }
            }
        }
        .section-calendar {
            .item-calendar {
                flex-direction: column;
                height: auto;

                .day-calendar {
                    max-width: 100%;
                    height: auto;
                }
                .content-calendar {
                    width: 100%;
                    padding: 16px;
                }
                .register-calendar {
                    max-width: 100%;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}

@media (max-width: 680px) {
    .events-calendar {
        .section-filter {
            .items-filter {
                flex-wrap: wrap;

                .item-filter {
                    width: 50%;
                    margin-bottom: 16px;
                    margin-right: 0;
                }
            }
        }
    }
}
