#features {
    margin-top: 70px;
    margin-bottom: 100px;
    position: relative;
    padding: 0px 24px;

    .section-title {
        margin-bottom: 24px !important;
    }

    .box-items {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 70px;
        max-width: 1440px;
        margin: 0 auto;

        .half-items {
            display: flex;
            align-items: center;
            justify-content: center;

            &:last-of-type {
                margin-top: 10px;
            }

            .item {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 300px;
                margin-bottom: 30px;
                padding: 30px;
                height: 226px;
                transition: all 0.3s;

                .box-icon {
                    width: 72px;
                    height: 72px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 24px;

                    .icon {
                        color: #fff;
                        font-size: 45px;
                    }
                }

                .item-title {
                    font-family: Urbanist-Bold;
                    font-size: 20px;
                    line-height: 24px;
                    text-align: center;
                    color: #000000;
                    margin-bottom: 16px;
                }
                .item-text {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    text-align: center;
                    color: rgba(0, 0, 0, 0.6);
                    position: relative;

                    &:after {
                        content: "";
                        width: 0%;
                        height: 2px;
                        transition: all 0.3s;
                        background: #26281f;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: -5px;
                    }
                }

                &:hover {
                    .item-text {
                        font-weight: 600;
                        &:after {
                            width: 50%;
                        }
                    }
                }
            }
        }
    }

    .content-features {
        .section-title {
            text-align: left;
            margin-bottom: 24px;
        }
        .text-features {
            font-weight: 400;
            font-size: 24px;
            line-height: 33px;
            color: rgba(0, 0, 0, 0.8);
            margin-bottom: 50px;
            max-width: 543px;
        }
    }

    .trail {
        position: absolute;
    }

    .trail-right {
        right: 0;
        top: 0;
    }

    .trail-left {
        left: 0;
        top: 65%;
    }

    .claim-btn {
        font-family: Urbanist-Bold;
        text-transform: capitalize;
        height: 60px !important;
        width: 200px !important;
        font-size: 20px;
        font-weight: 700;
        color: #ffffff;
        background: #4bb543;
        border-radius: 5px;
        border: 1px solid #4bb543;
        transition: all 0.3s;
        margin: 0 auto;
        display: flex;
        margin-top: 24px;

        &:hover {
            color: #4bb543;
            background: #ccffc8;
        }
    }
}

@media (max-width: 990px) {
    #features {
        .content-features {
            .section-title {
                text-align: center;
            }
            .box-items {
                .half-items {
                    flex-direction: column;

                    .side-line {
                        display: none;
                    }
                }
                .bottom-line {
                    display: none;
                }
            }
        }
        .trail-right,
        .trail-left {
            width: 80px;
        }
    }
}
