#membership {
    position: relative;
    margin-top: 100px;
    margin-bottom: 66px;

    .items {
        display: flex;
        justify-content: space-between;
        max-width: 960px;
        width: 100%;
        align-items: center;
        padding: 0px 24px;
        margin: 0 auto;
        margin-top: 48px;

        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            max-width: 240px;
            .box-icon {
                width: 72px;
                height: 72px;
                background: #26281f;
                box-shadow: 2px 4px 8px 2px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 24px;
            }
            .item-title {
                font-family: Urbanist-Bold;
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
                color: #000000;
                margin-bottom: 16px;
            }

            .item-text {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                text-align: center;
                color: rgba(0, 0, 0, 0.6);
            }
        }
    }

    .box-btn {
        max-width: 182px;
        width: 100%;
        margin: 0 auto;
        margin-top: 60px;

        button {
            width: 100% !important;
            font-size: 20px !important;
        }
    }

    .trail {
        position: absolute;
        z-index: -1;
    }
    .trail-left {
        left: 0;
        top: 0;
    }

    .trail-right {
        right: 0;
        top: -100px;
    }
}

@media (max-width: 990px) {
    #membership {
        .items {
            flex-direction: column;

            .item{
                margin-bottom: 24px;

                &:last-of-type{
                    margin-bottom: 0;
                }
            }
        }
        .trail-right {
          display: none;
        }
        .trail-left{
            width: 80px;
            top: 15%;
        }
    }
}
