#features-camping {
    margin-bottom: 72px;
    position: relative;
    .section-info {
        display: flex;
        width: 100%;
        max-width: 1440px;
        padding: 120px 24px 0px 24px;
        margin: 0 auto;

        .box-info {
            width: 50%;
            margin-right: 24px;
            .title {
                font-family: Urbanist-Bold;
                font-style: normal;
                font-weight: 700;
                font-size: 36px;
                line-height: 44px;
                color: #000000;
                margin-bottom: 72px;
            }
            .info {
                font-weight: 400;
                font-size: 20px;
                line-height: 27px;
                font-family: Urbanist-Regular;
                color: rgba(0, 0, 0, 0.8);
            }
        }
        .box-img {
            width: 50%;
            max-width: 684px;

            img {
                width: 100%;
            }
        }
    }
    .section-items {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        max-width: 920px;
        margin: 0 auto;
        margin-top: 72px;

        .item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 240px;

            .card-item {
                width: 72px;
                height: 72px;
                background: #26281f;
                box-shadow: 2px 4px 8px 2px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 24px;
            }

            .text-item {
                font-family: Urbanist-Regular;
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 27px;
                text-align: center;
                color: rgba(0, 0, 0, 0.6);
            }
        }
    }
    .box-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 48px;

        .features-btn {
            width: 147px;
            height: 48px;
            background: #4bb543;
            border-radius: 5px;
            text-transform: capitalize;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            font-family: Urbanist-Bold;
            color: #ffffff;
        }
    }
    .trail {
        position: absolute;
        z-index: -1;
    }
    .trail-left-1 {
        top: 0;
        left: 0;
    }
    .trail-left-2 {
        bottom: 0;
        left: 0;
    }
    .trail-right-1 {
        top: 0;
        right: 0;
    }
    .trail-right-2 {
        bottom: 0;
        right: 0;
    }
}

@media (max-width: 990px) {
    #features-camping {
        .section-info {
            flex-direction: column;
            align-items: center;
            .box-info {
                width: 100%;
                margin-bottom: 24px;
                text-align: center;
            }
            .box-img {
                width: 100%;
            }
        }
        .section-items {
            flex-direction: column;
            align-items: center;

            .item {
                margin-bottom: 24px;
            }
        }
        .trail {
            width: 350px;
        }
        .trail-right-1,
        .trail-right-2 {
            display: none;
        }
        .trail-left-2 {
            width: 100px;
        }
    }
}


@media (max-width: 425px) {
    #features-camping {
        .trail {
            width: 250px;
        }
        .trail-left-2 {
            width: 100px;
        }
    }
}
