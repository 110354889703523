* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Urbanist-Regular;
    font-style: normal;

    a {
        text-decoration: none;
    }

    li {
        list-style: none;
    }
}

html {
    scroll-behavior: smooth;
}

.icon-loading {
    color: #fff!important;
    width: 25px !important;
    height: 25px !important;
}

@font-face {
    font-family: Urbanist-Light;
    font-style: normal;
    src: url("./assets/fonts/Urbanist-Light.ttf");
}

@font-face {
    font-family: Urbanist-Regular;
    font-style: normal;
    src: url("./assets/fonts/Urbanist-Regular.ttf");
}

@font-face {
    font-family: Urbanist-Medium;
    font-style: normal;
    src: url("./assets/fonts/Urbanist-Medium.ttf");
}

@font-face {
    font-family: Urbanist-SemiBold;
    font-style: normal;
    src: url("./assets/fonts/Urbanist-SemiBold.ttf");
}

@font-face {
    font-family: Urbanist-Bold;
    font-style: normal;
    src: url("./assets/fonts/Urbanist-Bold.ttf");
}
