#hero {
    width: 100%;
    background-image: url(../../../assets/images/Home/hero-background.jpg);
    background-repeat: no-repeat;
    height: 95vh;
    background-attachment: fixed;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 24px;
    position: relative;

    &:after {
        content: "";
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.32);
    }

    .hero-content {
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        z-index: 1;

        .hero-title {
            font-family: Urbanist-Bold;
            font-weight: 700;
            font-size: 55px;
            line-height: 77px;
            color: #ffffff;
            margin-bottom: 24px;
        }

        .hero-text {
            font-family: Urbanist-Light;
            font-weight: 400;
            font-size: 32px;
            line-height: 44px;
            color: #ffffff;
            margin-bottom: 24px;
            max-width: 546px;
            text-shadow: 1px 1px 5px rgb(0, 0, 0);
        }

        .text-premium {
            //border: 2px dashed #fff;
            //text-align: center;
            border-radius: 4px;
            max-width: 450px !important;
        }

        .hero-items {
            display: flex;
            align-items: center;
            margin-bottom: 24px;
            .hero-text {
                margin-bottom: 0;
                max-width: 800px;
            }
            .loop-item {
                display: flex;
                align-items: center;
                margin-left: 10px;

                .box-icon {
                    width: 48px;
                    height: 48px;
                    border-radius: 100%;
                    background: #000000;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 8px;

                    img {
                        width: 70%;
                    }
                }
                .item-text {
                    display: block;
                    font-family: Urbanist-SemiBold;
                    font-size: 32px;
                    line-height: 44px;
                    text-align: center;
                    color: #fff;
                    text-shadow: 1px 1px 5px rgb(0, 0, 0);
                    //background: #fff;
                    //padding: 5px;
                    //border-radius: 4px;
                    position: relative;
                    //border: 2px dashed #FFF;

                    // &:after {
                    //     content: "";
                    //     width: 80%;
                    //     height: 3px;
                    //     position: absolute;
                    //     background: #4bb543;
                    //     bottom: 0;
                    //     left: 0;
                    // }
                }
            }
        }

        .hero-btns {
            max-width: 546px;
            display: flex;
            justify-content: space-between;

            .hero-btn {
                font-family: Urbanist-Bold;
                text-transform: capitalize;
                width: 250px;
                height: 53px;
                font-weight: 700;
                font-size: 20px;
                line-height: 29px;
                color: #ffffff;
                background: #4bb543;
                border-radius: 5px;
            }
        }

        .hero-form {
            max-width: 550px !important;
            display: flex;
            justify-content: flex-start;

            .hero-input {
                background: #fff;
                width: 305px !important;
                border-radius: 4px;
                margin-right: 15px;
                height: 60px !important;

                .MuiOutlinedInput-root {
                    height: 60px !important;
                    font-size: 20px !important;
                }
            }

            .hero-btn {
                font-family: Urbanist-Bold;
                text-transform: capitalize;
                height: 60px !important;
                width: 200px !important;
                font-size: 20px;
                font-weight: 700;
                color: #ffffff;
                background: #4bb543;
                border-radius: 5px;
                border: 1px solid #4bb543;
                transition: all 0.3s;

                &:hover {
                    color: #4bb543;
                    background: #ccffc8;
                }
            }
        }
        .info-text {
            width: 100%;
            color: #fff;
            display: flex;
            align-items: center;
            margin-top: 10px;
            font-size: 16px;
            line-height: 16px;

            svg {
                margin-right: 10px;
            }
        }
    }
}

@media (max-width: 990px) {
    #hero {
        background-position: bottom;
        background-attachment: scroll;

        .hero-content {
            .hero-title {
                font-size: 30px;
            }
            .hero-text {
                font-size: 25px;
            }
            .hero-items {
                .loop-item {
                    .item-text {
                        font-size: 25px;
                    }
                }
            }
            .hero-btns {
                flex-direction: column;

                .hero-btn {
                    &:last-of-type {
                        margin-top: 15px;
                    }
                }
            }

            .hero-form {
                .hero-input {
                    width: 80% !important;
                    margin-right: 0 !important;
                }
                .hero-btn {
                    width: 80% !important;
                }
            }
        }
    }
}

@media (max-width: 550px) {
    #hero {
        .hero-content {
            ul {
                max-height: unset;
                li {
                    width: 100%;
                }
            }
            .hero-form {
                flex-direction: column;

                .hero-btn {
                    &:last-of-type {
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}

@media (max-height: 450px), (max-width: 1000px) {
    #hero {
        .hero-content {
            margin-top: 75px;

            .hero-title {
                line-height: 40px;
                margin-bottom: 5px;
            }

            .hero-text,
            .hero-items {
                margin-bottom: 5px;
            }
        }
    }
}
