.app-btn {
    font-family: Urbanist-Bold!important;
    text-transform: capitalize!important;
    width: 250px!important;
    height: 53px!important;
    font-weight: 700!important;
    font-size: 22px!important;
    line-height: 29px!important;
    color: #FFF!important;
    background-color: #4bb543!important;
    border-radius: 5px!important;
    display: flex!important;
}

.btn-outlined {
    color: #4bb543!important;
    border: 1px solid #4bb543!important;
    background-color: transparent!important;
}
