#hero-events {
    width: 100%;
    height: 800px;
    background-image: url(../../../assets/images/Events/hero.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 24px;

    &:after {
        content: "";
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.32);
    }

    .box-info {
        width: 100%;
        z-index: 2;
        max-width: 527px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 100px;

        .hero-title {
            font-family: Urbanist-SemiBold;
            font-weight: 600;
            margin-bottom: 24px;
            font-size: 64px;
            line-height: 77px;
            color: #ffffff;
        }

        .hero-subtitle {
            font-family: Urbanist-SemiBold;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 33px;
            color: #ffffff;
            margin-bottom: 24px;
            text-align: center;
        }

        .hero-btns {
            display: flex;
            align-items: center;
            justify-content: center;

            .btn {
                width: 147px;
                height: 48px;
                font-family: Urbanist-Bold;
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                color: #ffffff;
                text-transform: capitalize;

                &.contained {
                    background: #4bb543;
                    border-radius: 5px;
                }

                &.outlined {
                    border: 1px solid #fff;
                    border-radius: 5px;
                }
            }
            span {
                font-family: Urbanist-Regular;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                color: rgba(255, 255, 255, 0.8);
                margin: 0px 23px;
            }
        }
    }
}

@media (max-width: 990px) {
    #hero-camping {
        .box-info {
        }
    }
}
